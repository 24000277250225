<template>
    <div class="px-3 py-2" style="direction:rtl">
        <v-form
            ref="form"
            lazy-validation
        >
            <b-sidebar backdrop id="payment_terms" width="50rem" style="direction:ltr" right title="شروط السداد" shadow >
            <v-divider></v-divider>
                <v-row class="m-2" style="direction:rtl">
                    <v-col cols="12">
                         <v-data-table
                            style="direction:rtl"
                            :headers="payheader"
                            :items="payRows"
                            :items-per-page="10"
                            :page.sync="page"
                            width="100%"
                            hide-default-footer
                            @page-count="pageCount = $event"
                            fixed-header
                            class="table-sm"
                        >
                            <template v-slot:item="row">
                                <tr>
                                    <td>{{ row.item.description }}</td>
                                    <td>{{ row.item.percent }}</td>
                                    <td>{{ row.item.amount }}</td>
                                </tr>
                            </template>
                         </v-data-table>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="description"
                            label="وصف الدفعة"
                            :rules="textrule"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="percent"
                            label="النسبة %"
                            type="number"
                            :rules="numberRule"
                            @change="clackAmount()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="amount"
                            label="المبلغ"
                            type="number"
                            readonly 
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            v-model="porder"
                            label="رقم الدفعة"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <b-button type="button" @click="addPaymentTerms()" class="btn btn-success btn-sm">إضافة</b-button>
                    </v-col>
                </v-row>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
export default{
    data() {
        return {
            textrule:[
                 v => !!v || 'هذا الحقل مطلوب',
            ],
            numberRule:[
                 v => !!v || 'هذا الحقل مطلوب',
                 v => (v && v != 0) || 'يرجى استخام رقم اكبر من الصفر للنسبة',
            ],
            payheader:[
                {
                    text: 'وصف الدفعة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'description',
                },
                {
                    text: 'النسبة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'percent',
                },
                {
                    text: 'المبلغ',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'amount',
                },
            ],
            payRows:[],
            page: 1,
            pageCount: 10,
            porder:1,
            description: '',
            percent: 0,
            amount: 0,
            pecentTot: 0,
            amountTot: 0,
            full_amount: 0
        }
    },
    methods: {
        clackAmount(){
            const per = parseFloat(this.percent);
            
            if(parseFloat(per) + parseFloat(this.pecentTot) > 100){
                this.percent = parseFloat(100) - parseFloat(this.pecentTot);
            }
            this.amount = parseFloat(this.full_amount) * parseFloat(this.percent) / parseFloat(100);
            this.amount = this.$RoundNum(this.amount);
        },
        collectData(){
            const order = this.$parent.crminfo;
            const products = this.$parent.crminfo.products;
            let ototal = 0;
            if(typeof products !== 'undefined')
                if(products.length > 0){
                    for(let i = 0; i< products.length ; i++){
                        ototal = parseFloat(ototal) + parseFloat(products[i].ftotal);
                    }
                }
            let payTotal = 0;
            let perTotal = 0;
            if(this.payRows.length > 0){
                for(let i = 0; i < this.payRows.length;i++){
                    payTotal = parseFloat(payTotal) + parseFloat(this.payRows[i].total);
                    perTotal = parseFloat(perTotal) + parseFloat(this.payRows[i].percent);
                }
            }
            ototal = this.$RoundNum(ototal);
            payTotal = this.$RoundNum(payTotal);
            this.full_amount = ototal;
            this.amountTot = payTotal;
            this.pecentTot = perTotal;

        },
        addPaymentTerms(){
            if(!this.$refs.form.validate()){
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","addpayTerms");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.$route.params.id);
            post.append("data[description]",this.description);
            post.append("data[percent]",this.percent);
            post.append("data[amount]",this.amount);
            post.append("data[porder]",this.porder);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                this.getPaymentTerms();
                setInterval(() => {this.collectData();},10000)
            })
        },
        getPaymentTerms(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getpayTerms");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.$route.params.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                this.payRows = response.data.results.data;
            })
        }
    },
    created() {
        this.getPaymentTerms();
        this.collectData();
    },
}
</script>